<script setup lang="ts">
import SVGHeart from '@mop/shared/images/misc/header-heart.svg?component';
import SVGHeartFilled from '@mop/shared/images/misc/heart-filled.svg?component';
import type { JobModel } from '@/types/jobModel';

defineOptions({
  name: 'JobListItem',
});

defineProps({
  job: {
    type: Object as PropType<JobModel>,
    required: true,
  },
});

const { toggleWishlistItem, wishlistItemExists } = useMopWishlist();
</script>

<template>
  <NuxtLink :to="$mopI18n.localePath(`${$urls.CAREER_PAGE}/${job.getUrl()}`)" class="job-list-item" no-prefetch>
    <div class="job-list-item__body">
      <h3 class="job-list-item__title">
        {{ job.getTitle() }}
      </h3>
      <div class="job-list-item__department">
        {{ job.getDepartment() }}
      </div>
      <div class="job-list-item__location">
        {{ job.getLocation() }}
      </div>
    </div>
    <div class="job-list-item__more">
      {{ $mopI18n.t('locale.jobs.see_more') }}
      <button class="job-list-item__wishlist" @click.stop.prevent="() => toggleWishlistItem(job)">
        <SVGHeart v-if="!wishlistItemExists(job.getId())" width="27" height="25" />
        <SVGHeartFilled v-else width="27" height="25" />
      </button>
    </div>
  </NuxtLink>
</template>

<style lang="scss" scoped>
.job-list-item {
  @include text-style(strong);

  text-transform: uppercase;
  padding: $space30 $space10;
  letter-spacing: 0.3px;
  position: relative;
  overflow: hidden;
  display: flex;
  height: 100%;
  align-items: center;

  @include apply-upto(small) {
    padding: $space60 $space10 80px;
  }
}

.job-list-item__more {
  @include text-style(strong);

  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: $black;
  color: $white;
  padding: 6px 0;
  text-align: center;
}

.job-list-item__title {
  font-size: 20px;
  line-height: 20px;
  padding: 0;
  margin: 0;
  min-height: 60px;
}

.job-list-item__department {
  font-size: 16px;
  line-height: 16px;
  margin-top: $space20;
  height: 32px;

  @include apply-upto(small) {
    height: auto;
  }
}

.job-list-item__location {
  font-size: 13px;
  line-height: 13px;
  margin-top: $space15;
}

.job-list-item__wishlist {
  background: $white;
  height: 29px;
  position: absolute;
  right: 0;
  bottom: 0;
  border: 0;
  line-height: 0;
  cursor: pointer;
  color: $black;
  width: 29px;
  overflow: hidden;
  padding: 0;
  margin: 0;
}
</style>
